import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Route, Switch } from "react-router-dom";
import Error from './Error';
import { log } from '../../utils';

const styles = theme => ({
  page: {
    maxWidth: 600,
    margin: 'auto',
    paddingBottom: theme.spacing(4),
  },
});

class Page extends React.Component {
  render() {
    log('Page.render', this.props)
    const { classes } = this.props;
    let { routes } = this.props;

    if (!routes) {
      routes = [];
    }

    return (
      <div className={classes.page}>
        <Switch>
          {routes.map((route, index) => {
            const Component = route.render;
            return (<Route
              key={index}
              path={route.path}
              render={() => <Component />}
            />)
          })}

          <Route
            render={() => <Error />}
          />
        </Switch>
      </div>
    );
  }
}

Page.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => (state);


export default withStyles(styles)(connect(
  mapStateToProps,
  null
)(Page));
