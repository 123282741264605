import { createMuiTheme } from '@material-ui/core/styles';

let theme = createMuiTheme({
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    primary: {
      main: '#629C44',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#7A7A7A',
    },
    error: {
      main: '#E61610',
      contrastText: '#000000',
    }
  },
  shape: {
    borderRadius: 8,
  },
});
//Background: #eaeff1

theme = {
  ...theme,
  overrides: {
    MuiCardHeader: {
      root: {
        alignItems: 'none',
      },
      title: {
        fontSize: '1rem'
      },
      subheader: {
        fontSize: '.8rem'
      },
      action: {
        marginTop: '0px',
        marginBottom: '0px',
        marginRight: '0px'
      }
    },
    MuiDialog: {
      paper: {
        borderRadius: '1px'
      }
    },
    MuiPaper: {
      root: {
        borderRadius: '1px'
      }
    },
    MuiDrawer: {
      paper: {
        backgroundColor: theme.palette.primary.dark,
      },
    },
    MuiButton: {
      root: {
        borderRadius: '2px'
      },
      label: {
        textTransform: 'none',
      }
    },
    MuiTabs: {
      root: {
        // marginLeft: theme.spacing(1)
      },
      indicator: {
        backgroundColor: theme.palette.common.secondary,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        padding: '0 16px',
        minWidth: 0,

        [theme.breakpoints.up('md')]: {
          padding: '0 16px',
          minWidth: 0,
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing(1),
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#DDDDDD',
      },
    },
    MuiListItemText: {
      primary: {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    MuiListItemIcon: {
      root: {
        color: 'inherit',
        marginRight: 0,
        '& svg': {
          fontSize: 20,
        },
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
    },
    MuiSvgIcon: {
      root: {
        // paddingBottom: '.1em',
        // fontSize: '1rem'
      }
    },
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    ...theme.mixins,
    toolbar: {
      minHeight: 48,
    },
  },
};



export default theme;