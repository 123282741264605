
export const GET_ACCOUNT = 'GET_ACCOUNT';

export const SIGNED_IN = 'SIGNED_IN';
export const SIGNED_OUT = 'SIGNED_OUT';
export const CREATE_ACCOUNT = 'CREATE_ACCOUNT';

export const CREATE_GOOGLE_SPREADSHEET = 'CREATE_GOOGLE_SPREADSHEET';
export const GET_GOOGLE_SPREADSHEETS = 'GET_GOOGLE_SPREADSHEETS';
export const UPDATE_GOOGLE_SPREADSHEET_TITLE = 'UPDATE_GOOGLE_SPREADSHEET_TITLE';
export const DELETE_GOOGLE_SPREADSHEET = 'DELETE_GOOGLE_SPREADSHEET';

export const CREATE_PLAID_ITEM = 'CREATE_PLAID_ITEM';
export const UPDATE_PLAID_ITEM = 'UPDATE_PLAID_ITEM';
export const GET_PLAID_ITEMS = 'GET_PLAID_ITEMS';
export const EXISTING_PLAID_ITEM_LINKED = 'EXISTING_PLAID_ITEM_LINKED';
export const EXISTING_PLAID_ITEMS_LINKED = 'EXISTING_PLAID_ITEMS_LINKED';
export const PLAID_ITEM_UNLINKED = 'PLAID_ITEM_UNLINKED';
export const REPAIR_PLAID_ITEM_SUCCESS = 'REPAIR_PLAID_ITEM_SUCCESS';
