import React from 'react';
import {
  Redirect, BrowserRouter as Router, Route, Switch
} from "react-router-dom";
import { connect } from 'react-redux';

import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import { GoogleScript } from './google';
import { Dashboard } from './layout';
import { PlaidItemManagement, SyncSheets, Error, SecurityAndPrivacy, SignIn } from './pages';


import { log } from '../utils';
import { isSignedIn } from '../services/auth';
import { setSignedIn, getAccount } from '../redux/actions';
import theme from './theme';


const dashboardRoutes = {
  accounts:
  {
    name: 'Linked Institutions',
    path: '/institutions',
    render: (props) => {
      return (<PlaidItemManagement {...props} />)
    }
  },
  syncSheets:
  {
    name: 'Sync Sheets',
    path: '/syncsheets',
    render: (props) => {
      return (<SyncSheets {...props} />)
    },
  },
  editSyncSheets:
  {
    name: 'Edit Sync Sheet',
    path: '/syncsheets/:id/edit',
    render: (props) => {
      return (<SyncSheets {...props} />)
    },
  },
  securityAndPrivacy:
  {
    name: 'Security & Privacy',
    path: '/security-and-privacy',
    render: (props) => <SecurityAndPrivacy {...props} />,
  },
  signout: {
    name: 'Sign Out',
    path: '/signout'
  }
};

class Index extends React.Component {

  componentDidMount() {
    log('components.index.componentDidMount', this.props);
  }

  componentDidUpdate() {
    log('components.index.componentDidUpdate', this.props);
  }

  handleScriptSigninSuccess = async (event) => {
    log('GoogleScript Success', event);
    this.props.setSignedIn(true);
    this.props.getAccount();
  }

  handleScriptSigninFailure = (event) => {
    this.props.setSignedIn(false);
    log('GoogleScript Failure', event);
  }

  render() {
    log('components.index.render', this.props);
    log('is user signed in?', isSignedIn())
    const { classes } = this.props;
    return (
      <>
        <GoogleScript
          accessType="offline"
          clientId={process.env['REACT_APP_GOOGLE_CLIENT_ID']}
          isSignedIn={true}
          onFailure={this.handleScriptSigninFailure}
          offline={true}
          onSuccess={this.handleScriptSigninSuccess}
          redirectUri={process.env['REACT_APP_GOOGLE_REDIRECT_URI']}
          responseType="code"
          scope="email profile openid https://www.googleapis.com/auth/spreadsheets https://www.googleapis.com/auth/drive.file" />

        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Router>
            <Switch>
              <Route
                path="/signin"
                render={() => (
                  isSignedIn() ? (
                    <Redirect to="/syncsheets" />
                  ) : (
                      <SignIn />
                    )
                )} />
              />

              <Route
                path="/"
                render={() => {
                  return !isSignedIn() ? (
                    <Redirect to="/signin" />
                  ) : (
                      <Dashboard classes={classes}
                        headerHomeRoute={dashboardRoutes.syncSheets}
                        headerLeftRoutes={[dashboardRoutes.syncSheets, dashboardRoutes.accounts]}
                        headerRightRoutes={[dashboardRoutes.signout]}
                        footerRoutes={[dashboardRoutes.securityAndPrivacy]}
                        pageRoutes={[dashboardRoutes.editSyncSheets]}
                      />
                    )
                }} />
              <Route
                render={() => <Error />}
              />
            </Switch>
          </Router>
        </ThemeProvider>
      </>
    );
  }
}



const mapStateToProps = (state) => ({
  account: state.account
});

const mapDispatchToProps = {
  setSignedIn, getAccount
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Index);