import React, { Component } from 'react'
import PropTypes from 'prop-types'


import { withStyles } from '@material-ui/core/styles';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ButtonContent from './button-content'

const styles = theme => ({
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
});

class GoogleLogin extends Component {
  constructor(props) {
    super(props)
    this.signIn = this.signIn.bind(this)
    this.enableButton = this.enableButton.bind(this)
    this.state = {
      disabled: true,
      hovered: false,
      active: false
    }
  }

  componentDidMount() {
    this.enableButton();
  }

  componentWillUnmount() {
    this.enableButton = () => { };
  }

  enableButton() {
    this.setState({
      disabled: false
    });
  }

  signIn(e) {
    if (e) {
      e.preventDefault(); // to prevent submit if used within form
    }
    if (!this.state.disabled) {
      const auth2 = window.gapi.auth2;
      const { onSuccess, onRequest, onFailure, prompt, responseType, scope } = this.props;
      const options = {
        prompt
      };
      onRequest();
      const authInstance = auth2.getAuthInstance();
      if (responseType === 'code') {
        authInstance.grantOfflineAccess({ scope, ...options }).then(res => onSuccess(res), err => onFailure(err));
      } else {
        authInstance.signIn(options).then(res => this.handleSigninSuccess(res), err => onFailure(err));
      }
    }
  }

  handleSigninSuccess(res) {
    /*
      offer renamed response keys to names that match use
    */
    const basicProfile = res.getBasicProfile();
    const authResponse = res.getAuthResponse();
    this.props.onSuccess({
      tokenId: authResponse.id_token,
      googleUserId: basicProfile.getId(),
      picture: basicProfile.getImageUrl(),
      email: basicProfile.getEmail(),
      name: basicProfile.getName(),
      givenName: basicProfile.getGivenName(),
      familyName: basicProfile.getFamilyName(),
    });
  }

  render() {
    const { children, classes } = this.props;
    return (<ButtonContent onButtonClick={this.signIn} props={this.props}> <FontAwesomeIcon className={classes.leftIcon} icon={faGoogle} /> {children}   </ButtonContent>)
  }
}

GoogleLogin.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onFailure: PropTypes.func.isRequired,
  clientId: PropTypes.string.isRequired,
  jsSrc: PropTypes.string,
  onRequest: PropTypes.func,
  buttonText: PropTypes.node,
  scope: PropTypes.string,
  className: PropTypes.string,
  redirectUri: PropTypes.string,
  cookiePolicy: PropTypes.string,
  loginHint: PropTypes.string,
  hostedDomain: PropTypes.string,
  children: PropTypes.node,
  disabledStyle: PropTypes.object,
  fetchBasicProfile: PropTypes.bool,
  prompt: PropTypes.string,
  tag: PropTypes.string,
  autoLoad: PropTypes.bool,
  disabled: PropTypes.bool,
  discoveryDocs: PropTypes.array,
  uxMode: PropTypes.string,
  isSignedIn: PropTypes.bool,
  responseType: PropTypes.string,
  additionalGrant: PropTypes.bool,
  type: PropTypes.string,
  accessType: PropTypes.string,
  render: PropTypes.func,
  theme: PropTypes.string,
  icon: PropTypes.bool,
  authStateShare: PropTypes.func
}

GoogleLogin.defaultProps = {
  type: 'button',
  tag: 'button',
  buttonText: 'Sign in with Google',
  scope: 'profile email',
  accessType: 'online',
  prompt: 'consent',
  cookiePolicy: 'single_host_origin',
  fetchBasicProfile: true,
  isSignedIn: false,
  uxMode: 'popup',
  disabledStyle: {
    opacity: 0.6
  },
  icon: true,
  theme: 'light',
  onRequest: () => { },
  jsSrc: 'https://apis.google.com/js/api.js',
  additionalGrant: false
}

export default withStyles(styles)(GoogleLogin);
