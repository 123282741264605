import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import {
  GoogleSpreadsheet as GoogleSpreadsheetIcon,
  PlusBox as PlusBoxIcon
} from 'mdi-material-ui';
import { DateTime } from 'luxon';
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Container,
  Divider,
  Grid,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField
} from '@material-ui/core'

import {
  DeleteForever as DeleteIcon,
  OpenInNew as OpenInNewIcon,
  Settings as SettingsIcon,
} from '@material-ui/icons';

import {
  ArrowTooltip,
  InlineEditor,
  ButtonRouter
} from '../../common';
import {
  createGoogleSpreadsheet,
  listGoogleSpreadsheets,
  updateGoogleSpreadsheetTitle,
  deleteGoogleSpreadsheet
} from '../../../redux/actions';
import { log } from '../../../utils';

const styles = theme => ({
  avatar: {
    margin: 10,
  },
  bigAvatar: {
    margin: 10,
    width: 60,
    height: 60,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '1px'
  },
  cardActions: {
    display: 'flex'
  },
  cardDivider: {
    border: 'none',
    height: '1px',
    margin: 0,
    flexShrink: 0,
    backgroundColor: theme.palette.secondary.light
  },
  cardLeftAction: {
    textAlign: 'left',
  },
  cardRightAction: {
    marginLeft: 'auto',
    textAlign: 'right',
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  rightIconLarge: {
    fontSize: '1.2rem',
    marginLeft: theme.spacing(1),
  },
  rightIconMedium: {
    fontSize: '1.0rem',
    marginLeft: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(.5),
  },
  leftIconMedium: {
    fontSize: '1.0rem',
    marginRight: theme.spacing(1),
  },
  leftIconLarge: {
    fontSize: '1.2rem',
    marginRight: theme.spacing(1),
  },
  timestamp: {
    float: 'right'
  }
});

function GSFormDialog(props) {
  const { classes, onSave } = props;
  const [open, setOpen] = React.useState(false);
  let title = '';

  function handleClickOpen() {
    setOpen(true);
  }
  function handleClose() {
    setOpen(false);
  }
  function handleCancel() {
    setOpen(false);
  }
  function handleCreate() {
    setOpen(false);
    onSave(title);
  }
  function handleTextFieldChange(e) {
    title = e.target.value;
  }
  return (
    <div>
      <Button
        size="small"
        variant="contained"
        color="primary"
        onClick={(e) => { handleClickOpen(); }}>
        <PlusBoxIcon className={classes.leftIconLarge} />
        New
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        {/* <DialogTitle id="form-dialog-title"></DialogTitle> */}
        <DialogContent>
          <DialogContentText>
            Enter the name of your new Google Spreadsheet
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            fullWidth
            required
            onChange={(e, value) => { handleTextFieldChange(e, value); }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleCreate} color="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </div >
  );
}

function GSCardTitle(props) {
  const { text, onSave } = props;
  const onInputSave = (title) => {
    onSave(title);
  }
  return (
    <ArrowTooltip title="Click to edit" placement="right">
      <InlineEditor onSave={(value) => { onInputSave(value) }} value={text} />
    </ArrowTooltip>
  );
}

function GSCard(props) {
  const { classes, spreadsheet, onDeleteClick } = props;
  return (
    <Card className={classes.card}>
      <CardHeader
        avatar={
          <GoogleSpreadsheetIcon color="primary" />
        }
        title={
          <Typography variant="inherit" component="h3" color="primary">
            {spreadsheet.title}
          </Typography>
        }
        subheader=""
        action={
          <Typography className={classes.timestamp} variant="caption" display="block" gutterBottom color="secondary">
            Last updated {DateTime.fromISO(spreadsheet.updatedAt).toRelative()}...
          </Typography>
        }
      />
      <CardContent>
        {
          spreadsheet.plaidItems.length ?
            <Typography variant="body2" component="p" color="secondary">
              <b>Institutions</b>: {spreadsheet.plaidItems.map((pl) => { return pl.name }).join(', ')}
            </Typography>
            : ''
        }
      </CardContent>
      <Divider className={classes.cardDivider} />
      <CardActions className={classes.cardActions}>
        <ButtonRouter size="small" color="primary" className={classes.link} to={"/syncsheets/" + spreadsheet.spreadsheetId + "/edit"}
          startIcon={<SettingsIcon />}>
          Settings
        </ButtonRouter>

        <Button size="small" color="primary" className={classes.cardLeftAction}
          target="_blank" rel="noopener" href={spreadsheet.spreadsheetUrl}
          startIcon={<OpenInNewIcon />}>
          Open
        </Button>
        <Button size="small" className={classes.cardRightAction}
          onClick={(e) => { onDeleteClick(e, spreadsheet) }}
          startIcon={<DeleteIcon />}>
          Remove
        </Button>
      </CardActions>
    </Card>
  )
}


class SyncSheets extends React.Component {
  constructor(props) {
    super(props);
    this.onLinkAccountsClick = this.onLinkAccountsClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onSaveTitle = this.onSaveTitle.bind(this);
    this.onCreateClick = this.onCreateClick.bind(this);
  }

  componentDidMount() {
    log('SyncSheets.componentDidMount', this.props);
    this.props.listGoogleSpreadsheets(this.props.account.accountId);
  }

  componentDidUpdate() {
    log('SyncSheets.componentDidUpdate', this.props);
  }

  onCreateClick(title) {
    this.props.createGoogleSpreadsheet(this.props.account.accountId, title);
  }

  onSaveTitle(accountId, spreadsheetId, title) {
    this.props.updateGoogleSpreadsheetTitle(accountId, spreadsheetId, title);
  }

  onLinkAccountsClick(e, spreadsheet) {
    e.preventDefault();
    alert(`Are you sure you want to edit ${JSON.stringify(spreadsheet, null, 2)} ?`)
  }

  onDeleteClick(e, spreadsheet) {
    e.preventDefault();
    if (confirm(`Are you sure you want to delete ${spreadsheet.title} ?`)) {//eslint-disable-line
      this.props.deleteGoogleSpreadsheet(this.props.account.accountId, spreadsheet.spreadsheetId);
    }
  }

  render() {
    log('SyncSheets.render', this.props)
    const { classes, google } = this.props;
    return (
      <>
        <Container className={classes.cardGrid} maxWidth="md">
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={12}>
              <GSFormDialog onSave={this.onCreateClick} classes={classes} />
            </Grid>
            {
              !google.renderEmptyListMessage
                ?
                google.googleSpreadsheets.map((spreadsheet, index) => {
                  return (
                    <React.Fragment key={index}>

                      <Grid item xs={12} sm={12} md={12}>
                        <GSCard
                          classes={classes}
                          spreadsheet={spreadsheet}
                          onSaveTitle={this.onSaveTitle}
                          onLinkAccountsClick={this.onLinkAccountsClick}
                          onDeleteClick={this.onDeleteClick}
                        />
                      </Grid>

                    </ React.Fragment>
                  )
                })
                :
                <Grid item xs={12} sm={12} md={12}>
                  <Typography>
                    No Google Sheets available
                  </Typography>
                </Grid>
            }
          </Grid>
        </Container>
      </>
    );
  }
}


GSFormDialog.propTypes = {
  onSave: PropTypes.func.isRequired
};

GSCardTitle.propTypes = {
  onSave: PropTypes.func.isRequired
};

SyncSheets.propTypes = {
};

const mapStateToProps = (state) => ({
  account: state.account,
  google: state.google
})

const mapDispatchToProps = {
  createGoogleSpreadsheet,
  listGoogleSpreadsheets,
  updateGoogleSpreadsheetTitle,
  deleteGoogleSpreadsheet
};

export default withStyles(styles)(connect(
  mapStateToProps,
  mapDispatchToProps
)(SyncSheets));
