import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  List,
  ListItem,
  ListItemText,
  DialogTitle,
  Grid,
  DialogActions,
  Dialog,
  Divider,
  ListItemIcon,
  Checkbox
} from "@material-ui/core";
import {
  AccountBalance as AccountBalanceIcon
} from '@material-ui/icons';
import PlaidLink from './PlaidLink';

const useStyles = makeStyles(theme => ({
  flexSection: {
    flexGrow: 1,
    display: "flex",
    minHeight: "50%"
  },
  flexColScroll: {
    flexGrow: 1,
    overflow: "auto",
    minHeight: "100%"
  },

  flexColScrollPadding: {
    padding: "10px",
    flexGrow: 1,
    overflow: "auto",
    minHeight: "100%"
  },
  flexNoShrink: {
    flexShrink: 0
  },
  paper: {
    width: "50%"
  },
  noPadding: {
    padding: "0px"
  },
  dialogList: {
    paddingTop: "0px"
  },
  dialogListItem: {
    borderRight: "1px solid rgba(0, 0, 0, 0.12)"
  },
  dialogListItemText: {
    overflow: "hidden",
    display: "-webkit-box",
    "-webkit-line-clamp": 3,
    "-webkit-box-orient": "vertical"
  }
}));

function ConfirmationDialogRaw(props) {
  const classes = useStyles();
  const { items, onSuccess, onExit, onClose, onLinkExisting, open, ...other } = props;
  const [checked, setChecked] = React.useState([]);

  const handleToggle = value => () => {
    const currentIndex = checked.findIndex(item => {
      return item.itemId === value.itemId;
    });
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleCancel = () => {
    onClose();
    setChecked([]);
  };

  const handleOk = () => {
    if (checked.length) {
      onLinkExisting(checked);
    }
    setChecked([]);
    onClose();
  };

  return (
    <Dialog
      maxWidth="xl"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      onClose={handleCancel}
      // disableBackdropClick
      // disableEscapeKeyDown
      {...other}
    >
      <DialogTitle id="confirmation-dialog-title">
        Link an Institution to Your Google Sheet
      </DialogTitle>
      <Divider />
      <Grid container className={classes.flexSection}>
        <Grid item xs={6} className={classes.flexColScroll}>

          <List className={classes.dialogList} component="div" role="list">
            {items.map(item => {
              return (
                <ListItem
                  dense
                  button
                  divider
                  aria-haspopup="true"
                  aria-label={item.name}
                  className={classes.dialogListItem}
                  role="listitem"
                  key={item.itemId}
                  onClick={handleToggle(item)}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={
                        checked.findIndex(it => {
                          return item.itemId === it.itemId;
                        }) !== -1
                      }
                      tabIndex={-1}
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={item.name}
                    className={classes.dialogListItemText}
                    secondary={item.accounts.map((account) => { return account.name }).join(", ")}
                  />
                </ListItem>
              );
            })}
          </List>

        </Grid>

        <Grid item xs={6} className={classes.flexColScrollPadding}>
          <PlaidLink
            size="small"
            variant="contained"
            color="primary"
            clientName="Bank2Sheets"
            env={process.env['REACT_APP_PLAID_ENV']}
            product={["auth", "transactions"]}
            publicKey={process.env['REACT_APP_PLAID_PUBLIC_KEY']}
            webhook={process.env['REACT_APP_LINK_WEBHOOK_URL']}
            onOpen={() => { handleCancel(false); }}
            onSuccess={props.onSuccess}
            onExit={props.onExit}
            style={null}
          >
            Link
            </PlaidLink>
        </Grid>
      </Grid>

      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={handleOk} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialogRaw.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

export default function ConfirmationDialog(props) {
  const { items, onSuccess, onExit, onLinkExisting } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>

      {items.length ?
        <>
          <Button
            onClick={() => {
              handleClickOpen();
            }}
            size="small"
            variant="contained"
            color="primary"
            startIcon={<AccountBalanceIcon />}
          >
            Select Institution
          </Button>
          <ConfirmationDialogRaw
            classes={{
              paper: classes.paper
            }}
            keepMounted
            open={open}
            onLinkExisting={onLinkExisting}
            onClose={handleClose}
            onSuccess={onSuccess}
            onExit={onExit}
            items={items}
          />
        </> : <PlaidLink
          size="small"
          variant="contained"
          color="primary"
          clientName="Bank2Sheets"
          env={process.env['REACT_APP_PLAID_ENV']}
          product={["auth", "transactions"]}
          publicKey={process.env['REACT_APP_PLAID_PUBLIC_KEY']}
          webhook={process.env['REACT_APP_LINK_WEBHOOK_URL']}
          onSuccess={onSuccess}
          onExit={onExit}
          style={null}
        >
          Link
      </PlaidLink>}
    </div >
  );
}
