import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({});

class SecurityAndPrivacy extends React.Component {
  render() {
    return (
      <>
        Security & Privacy
      </>
    );
  }
}

SecurityAndPrivacy.propTypes = {
};

export default withStyles(styles)(SecurityAndPrivacy);
