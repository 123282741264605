import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom'

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  main: {
    paddingTop: theme.spacing(2),
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
  footer: {
    padding: theme.spacing(2),
    marginTop: 'auto',
    backgroundColor: 'white',
  },
  link: {
    margin: theme.spacing(1, 1.5),
    textDecoration: 'none',
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  }
});

class Footer extends React.Component {
  render() {
    const { classes, routes } = this.props;

    return (
      <footer className={classes.footer}>
        <Container >
          <Grid container spacing={2} justify="center" alignItems="center">
            {routes.map((route, index) => {
              return (<Grid key={index} item>
                <Link className={classes.link} to={route.path}>
                  {route.name}
                </Link>
              </Grid>)
            })}
          </Grid>
        </Container>
      </footer>
    );
  }
}

export default withStyles(styles)(Footer);