import React from 'react'
import {
  Button,
} from '@material-ui/core';


const ButtonContent = ({ onButtonClick, color, variant, size, startIcon, children }) => (
  <>
    <Button onClick={onButtonClick} color={color} variant={variant} size={size} startIcon={startIcon}>
      {children}
    </Button >
  </>
)

export default ButtonContent
