import React from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Typography
} from '@material-ui/core';

import PlaidLink from './PlaidLink';
import PlaidItemSelectDialog from './PlaidItemSelectDialog';
import PlaidItemCard from './PlaidItemCard';

import {
  createPlaidItem,
  repairPlaidItem,
  removePlaidItem,
  createPlaidLinksToGoogleSpreadsheet,
  deletePlaidLinkToGoogleSpreadsheet
} from '../../redux/actions';
import { log } from '../../utils';




class PlaidItemContainer extends React.Component {
  constructor(props) {
    super(props);
    this.onUnlinkFromSyncSheetClick = this.onUnlinkFromSyncSheetClick.bind(this);
    this.onRemoveClick = this.onRemoveClick.bind(this);
    this.onManageSyncClick = this.onManageSyncClick.bind(this);
    this.handlePlaidLinkSuccess = this.handlePlaidLinkSuccess.bind(this);
    this.handlePlaidLinkExit = this.handlePlaidLinkExit.bind(this);
  }

  state = {
    accountId: this.props.accountId,
    spreadsheetId: this.props.spreadsheetId || undefined,
    items: this.props.items
  };

  componentDidMount() {
    log('PlaidItemContainer.componentDidMount', this.props);
  }

  componentDidUpdate(previousProps, previousState) {
    log('PlaidItemContainer.componentDidUpdate', this.props);
    if (JSON.stringify(previousProps.items) !== JSON.stringify(this.props.items)) {
      this.setState((state) => {
        return { items: this.props.items };
      });
    }
  }

  handlePlaidRepairSuccess = (token, item) => {
    this.props.repairPlaidItem(this.props.accountId, token, item);
  }

  handleLinkExisting = async (selectedItems) => {
    const { spreadsheetId } = this.state;
    if (spreadsheetId) {

      await this.props.createPlaidLinksToGoogleSpreadsheet({ accountId: this.props.accountId, spreadsheetId, items: selectedItems });
    }
  }


  handlePlaidLinkSuccess = (_, metadata) => {
    const { spreadsheetId } = this.state;
    if (spreadsheetId) {
      this.props.createPlaidItem(this.props.accountId, { ...metadata, spreadsheetId });
    } else {
      this.props.createPlaidItem(this.props.accountId, metadata);
    }
  }

  handlePlaidLinkExit() {
  }

  onManageSyncClick(e, item) {
    e.preventDefault();
  }

  onUnlinkFromSyncSheetClick(e, item) {
    e.preventDefault();
    const { spreadsheetId } = this.state;
    if (confirm(`Are you sure you want to unlink this institution?`)) {//eslint-disable-line
      this.props.deletePlaidLinkToGoogleSpreadsheet({ accountId: this.props.accountId, itemId: item.itemId, spreadsheetId });
    }
  }


  onRemoveClick(e, item) {
    e.preventDefault();

    if (confirm(`Are you sure you want to remove your ${item.name} accounts?`)) {//eslint-disable-line
      this.props.removePlaidItem(this.props.accountId, item.itemId);
      this.setState((state) => {
        const indexToRemove = state.items.findIndex((i) => {
          return i.itemId === item.itemId;
        });
        state.items.splice(indexToRemove, 1);
        return { items: state.items }
      });
    }
  }

  render() {
    log('PlaidItemContainer.render', this.props);
    const { classes, availableItems, renderEmptyListMessage } = this.props;
    const { items, spreadsheetId } = this.state;

    return (
      <>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={12}>
            {this.props.spreadsheetId
              ?
              <PlaidItemSelectDialog
                items={availableItems}
                onLinkExisting={this.handleLinkExisting}
                onSuccess={this.handlePlaidLinkSuccess}
                onExit={this.handlePlaidLinkExit} />
              : <PlaidLink
                size="small"
                variant="contained"
                color="primary"
                clientName="Bank2Sheets"
                env={process.env['REACT_APP_PLAID_ENV']}
                product={["auth", "transactions"]}
                publicKey={process.env['REACT_APP_PLAID_PUBLIC_KEY']}
                webhook={process.env['REACT_APP_LINK_WEBHOOK_URL']}
                onSuccess={this.handlePlaidLinkSuccess}
                onExit={this.handlePlaidLinkExit}
                style={null}
              >
                Link
            </PlaidLink>}
          </Grid>
          {
            !renderEmptyListMessage
              ?
              items.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <Grid item xs={12} sm={12} md={12}>
                      <PlaidItemCard
                        classes={classes}
                        item={item}
                        handlePlaidRepairSuccess={this.handlePlaidRepairSuccess}
                        handlePlaidLinkExit={this.handlePlaidLinkExit}
                        onRemoveClick={this.onRemoveClick}
                        onUnlinkFromSyncSheetClick={this.onUnlinkFromSyncSheetClick}
                        onManageSyncClick={this.onManageSyncClick}
                        spreadsheetId={spreadsheetId} />
                    </Grid>
                  </ React.Fragment>
                )
              })
              : <Grid item xs={12} sm={12} md={12}>
                {
                  !!spreadsheetId ?
                    <Typography>
                      No financial institutions linked.
                </Typography> :
                    <Typography>
                      No financial institutions connected.
                </Typography>
                }
              </Grid>
          }
        </Grid>
      </>
    );
  }
}

PlaidItemContainer.propTypes = {
};

const mapDispatchToProps = {
  createPlaidItem,
  repairPlaidItem,
  removePlaidItem,
  createPlaidLinksToGoogleSpreadsheet,
  deletePlaidLinkToGoogleSpreadsheet
};

export default connect(
  null,
  mapDispatchToProps
)(PlaidItemContainer);
