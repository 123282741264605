import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles';



const styles = theme => ({
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
});

class GoogleLogout extends Component {
  constructor(props) {
    super(props);
    this.signOut = this.signOut.bind(this);
    this.state = {
      disabled: true,
      hovered: false,
      active: false
    };
  }

  signOut() {
    if (window.gapi) {
      const auth2 = window.gapi.auth2.getAuthInstance();
      if (auth2 != null) {

        return auth2.signOut().then(this.props.onLogoutSuccess);//auth2.disconnect().then(this.props.onLogoutSuccess))
      } else {
        return this.props.onLogoutSuccess();
      }
    }
  }

  render() {
    const {
      children
    } = this.props;
    return (
      <div onClick={this.signOut}>
        {children}
      </div>
    )
  }
}

GoogleLogout.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  onLogoutSuccess: PropTypes.func
};

export default withStyles(styles)(GoogleLogout);
