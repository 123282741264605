import React from "react";
import { connect } from 'react-redux';
import {
  useParams
} from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import EditSyncSheet from './EditSyncSheet';
import SyncSheetsList from './SyncSheetsList';



const styles = theme => ({
  root: {
    flexGrow: 1,
    borderRadius: '1px'
  },
  tabs: {
    borderBottom: '1px solid #aaa'
  }
});

// function TabPanel(props) {
//   const { children, value, index, ...other } = props;

//   return (
//     <Typography
//       component="div"
//       role="tabpanel"
//       hidden={value !== index}
//       id={`full-width-tabpanel-${index}`}
//       {...other}
//     >
//       <Box p={3}>{children}</Box>
//     </Typography>
//   );
// }

// class CenteredTabs extends React.Component {
//   constructor(props) {
//     super(props);

//     this.state = { value: 0 };
//     this.handleChange = this.handleChange.bind(this);
//   }

//   handleChange(_, newValue) {
//     this.setState({ value: newValue });
//   }

//   render() {
//     return (<>
//       {this.props.account.accountId ? <SyncSheets /> : ''}
//     </>
//     );
//   }
// }

const mapStateToProps = (state) => (
  {
    account: state.account
  }
)

const mapDispatchToProps = {

};


function SyncSheets(props) {
  const { account } = props;
  const { id } = useParams();
  return (
    <>
      {account.accountId ?
        <> {
          id ?
            <EditSyncSheet id={id} />
            : <SyncSheetsList />}
        </>
        : ''}
    </>
  );
}

export default withStyles(styles)(connect(
  mapStateToProps,
  mapDispatchToProps
)(SyncSheets));
