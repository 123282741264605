
export const log = (message) => {
  if (process.env.LOGGING_ENABLED) {
    console.log(message);
  }
};

export const logError = (message) => {
  if (process.env.LOGGING_ENABLED) {
    console.error(message);
  }
};