import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { DateTime } from 'luxon';
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Typography
} from '@material-ui/core';

import {
  DeleteForever as DeleteIcon
} from '@material-ui/icons';

import { ButtonLink } from '../common';
import PlaidLink from './PlaidLink';

import {
} from '../../redux/actions';
import { log } from '../../utils';

const styles = theme => ({
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  name: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  cardGrid: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '1px'
  },
  cardActions: {

  },
  cardContent: {
    display: 'flex',
    padding: '8px'
  },
  cardDivider: {
    border: 'none',
    height: '1px',
    margin: 0,
    flexShrink: 0,
    backgroundColor: theme.palette.secondary.light
  },
  cardLeftAction: {
    textAlign: 'left',
  },
  cardLeftActionError: {
    color: theme.palette.error.main,
  },
  cardRightAction: {
    marginLeft: 'auto',
    textAlign: 'right',
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  timestamp: {
    float: 'right'
  }
});

function GSSLinks(props) {
  const { items } = props;
  return (
    <>{
      items.map((item, index) => {
        log(items, item)
        return (
          <span key={index}>
            <ButtonLink text={item.title} url={item.spreadsheetUrl} />{index === items.length - 1 ? '' : ', '}
          </span>
        )
      })
    }</>
  );
}

function PlaidItemCard(props) {
  const { classes, item, onRemoveClick, onUnlinkFromSyncSheetClick, handlePlaidRepairSuccess, handlePlaidLinkExit } = props;

  return (
    <Card className={classes.card}>
      <CardHeader
        // avatar={
        //   <GoogleSpreadsheetIcon color="primary" />
        // }
        title={
          <Typography variant="inherit" component="h3" color="primary">
            {item.name}
          </Typography>
        }
        subheader=""
        action={
          <Typography className={classes.timestamp} variant="caption" display="block" gutterBottom color="secondary">
            Last sync happened {DateTime.fromISO(item.lastSuccessfulUpdate).toRelative()}...
          </Typography>
        }
      />
      <CardContent>
        <Typography variant="body2" component="p" color="secondary">
          <b>Accounts</b>: {item.accounts.map((account) => {
            return account.name
          }).join(', ')}
        </Typography>
        {
          item.googleSpreadsheets && item.googleSpreadsheets.length ?
            <Typography variant="body2" component="p" color="secondary">
              <b>Google Sheets</b>: {
                <GSSLinks items={item.googleSpreadsheets} />
              }
            </Typography>
            : ''
        }
      </CardContent>
      <Divider className={classes.cardDivider} />
      <CardActions className={classes.cardActions}>
        {!!item.error ?
          <>
            <PlaidLink
              size="small"
              color="primary"
              clientName="Bank2Sheets"
              env={process.env['REACT_APP_PLAID_ENV']}
              product={["auth", "transactions"]}
              publicKey={process.env['REACT_APP_PLAID_PUBLIC_KEY']}
              token={item.publicToken}
              onSuccess={(token, metadata) => { handlePlaidRepairSuccess(token, item) }}
              onExit={handlePlaidLinkExit}
              style={null}
              error={true}
            >
              Repair
            </PlaidLink>
            <Typography variant="caption" display="block" color="error">
              {item.error.displayMessage}
            </Typography>
          </>
          : ''}

        {
          props.spreadsheetId
            ?
            <Button size="small" className={classes.cardRightAction}
              onClick={(e) => { onUnlinkFromSyncSheetClick(e, item) }}
              startIcon={<DeleteIcon />}>
              Unlink
        </Button>
            :
            <Button size="small" className={classes.cardRightAction}
              onClick={(e) => { onRemoveClick(e, item) }}
              startIcon={<DeleteIcon />}>
              Remove
            </Button>
        }
      </CardActions>
    </Card>
  )
}



PlaidItemCard.propTypes = {
};

const mapStateToProps = (state) => ({
  plaid: state.plaid
});

const mapDispatchToProps = {
};

export default withStyles(styles)(connect(
  mapStateToProps,
  mapDispatchToProps
)(PlaidItemCard));
