import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  AppBar,
  Avatar,
  IconButton,
  Grid,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
  ListItemIcon
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import AccountCircle from '@material-ui/icons/AccountCircle';

import { log } from '../../utils';
import { GoogleLogout } from '../google';
import { setSignedIn } from '../../redux/actions';

const lightColor = 'rgba(255, 255, 255, 0.7)';

const styles = theme => ({
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
    cursor: 'pointer'
  },
  link: {
    margin: theme.spacing(1, 1.5),
    textDecoration: 'none',
    color: lightColor,
    '&:hover': {
      color: theme.palette.common.white,
    },
  },
  avatar: {
    margin: 10,
  },
  bigAvatar: {
    margin: 10,
    width: 60,
    height: 60,
  },
});

function AccountMenu(props) {
  const { account, classes } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleSignoutSuccess() {
    log('components.layout.Header.handleSignoutSuccess');
    props.setSignedIn(false);
    setAnchorEl(null);
  }

  return (
    <div>
      <IconButton
        onClick={handleClick}
        color="inherit">
        <Avatar alt={account.name} src={account.picture} className={classes.avatar} />
      </IconButton>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <GoogleLogout
          variant="text"
          color="inherit"
          size="small"
          onLogoutSuccess={handleSignoutSuccess}
        >
          <MenuItem>
            <ListItemIcon>
              <AccountCircle />
            </ListItemIcon>
            Sign Out
        </MenuItem>
        </GoogleLogout>
      </Menu>
    </div>
  );
}

class Header extends React.Component {
  render() {
    const { classes, homeRoute = '/', leftRoutes = [], rightRoutes = [] } = this.props;
    return (
      <>
        <AppBar position="static" color="primary" elevation={0} className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <Typography variant="h6" color="inherit" onClick={() => {
              window.location.href = `${process.env['REACT_APP_HOME']}${homeRoute.path}`;
            }} noWrap className={classes.toolbarTitle}>
              Bank2Sheets
            </Typography>
            <nav>

              {leftRoutes.map((route, index) => {
                return (<Link key={index} className={classes.link} to={route.path}>
                  {route.name}
                </Link>)
              })}

              <Grid item xs />
              {rightRoutes.map((route, index) => {
                return (<Link className={classes.link} to={route.path}>
                  {route.name}
                </Link>)
              })}
            </nav>
            <AccountMenu classes={classes} account={this.props.account} setSignedIn={this.props.setSignedIn} />
          </Toolbar>
        </AppBar>
      </>
    );
  }
};

Header.propTypes = {
  classes: PropTypes.object.isRequired
};


const mapStateToProps = (state) => (
  {
    account: state.account
  }
)

const mapDispatchToProps = {
  setSignedIn
};

export default withStyles(styles)(connect(
  mapStateToProps,
  mapDispatchToProps
)(Header));