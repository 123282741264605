import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import {
  Container,
  Grid,
  Typography
} from '@material-ui/core'

import {
  CheckCircleOutline as CheckCircleOutlineIcon
} from '@material-ui/icons';

import {
  InlineEditor,
  ButtonRouter
} from '../../common';
import {
  listGoogleSpreadsheets,
  updateGoogleSpreadsheetTitle,
  listPlaidItems
} from '../../../redux/actions';
import { PlaidItemsContainer } from '../../plaid';
import { log } from '../../../utils';

const styles = theme => ({
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  cardGrid: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '1px'
  },
  cardActions: {
    display: 'flex'
  },
  cardDivider: {
    border: 'none',
    height: '1px',
    margin: 0,
    flexShrink: 0,
    backgroundColor: theme.palette.secondary.light
  },
  cardLeftAction: {
    textAlign: 'left',
  },
  cardRightAction: {
    marginLeft: 'auto',
    textAlign: 'right',
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  rightIconLarge: {
    fontSize: '1.2rem',
    marginLeft: theme.spacing(1),
  },
  rightIconMedium: {
    fontSize: '1.0rem',
    marginLeft: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(.5),
  },
  leftIconMedium: {
    fontSize: '1.0rem',
    marginRight: theme.spacing(1),
  },
  leftIconLarge: {
    fontSize: '1.2rem',
    marginRight: theme.spacing(1),
  },
  timestamp: {
    float: 'right'
  }
});

class EditSyncSheet extends React.Component {
  constructor(props) {
    super(props);
    this.onInputSave = this.onInputSave.bind(this);
    this.onLink = this.onLink.bind(this);
  }

  componentDidMount() {
    log('EditSyncSheet.componentDidMount', this.props);
    this.props.listPlaidItems(this.props.account.accountId);
  }

  componentDidUpdate() {
    log('EditSyncSheet.componentDidUpdate', this.props);
  }

  onLink(accountId, spreadsheetId, plaidMetadata) {
    log('EditSyncSheet.onLink', accountId, spreadsheetId);
  }

  onInputSave(accountId, spreadsheetId, value) {
    this.props.updateGoogleSpreadsheetTitle(accountId, spreadsheetId, value);
  }

  render() {
    log('EditSyncSheet.render', this.props)
    const { classes, google, id } = this.props;
    const spreadsheet = google.googleSpreadsheets.find((ss) => { return ss.spreadsheetId === id });
    if (!spreadsheet) {
      this.props.history.push('/syncsheets');
      return (<></>);
    }


    return (
      <Container className={classes.cardGrid} maxWidth="md">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={12}>
            <Typography variant="body1" component="span" color="primary">
              <InlineEditor onSave={(value) => {
                this.onInputSave(this.props.account.accountId, spreadsheet.spreadsheetId, value)
              }} value={spreadsheet.title} />
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <PlaidItemsContainer onLink={(plaidMetadata) => {
              this.onLink(this.props.account.accountId, spreadsheet.spreadsheetId, plaidMetadata)
            }}
              accountId={this.props.account.accountId}
              spreadsheetId={spreadsheet.spreadsheetId}
              items={spreadsheet.plaidItems}
              renderEmptyListMessage={!spreadsheet.plaidItems.length}
              availableItems={this.props.plaid.items.filter((item1) => {
                return !spreadsheet.plaidItems.some((item2) => {
                  return item2.itemId === item1.itemId;
                });
              })}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <ButtonRouter variant="contained" className={classes.link} to={"/syncsheets"}>
              <CheckCircleOutlineIcon className={classes.leftIcon} />
              Done
            </ButtonRouter>
          </Grid>
        </Grid>
      </Container>
    );
  }
}


EditSyncSheet.propTypes = {
};

const mapStateToProps = (state) => ({
  account: state.account,
  google: state.google,
  plaid: state.plaid
})

const mapDispatchToProps = {
  updateGoogleSpreadsheetTitle,
  listGoogleSpreadsheets,
  listPlaidItems
};

export default withRouter(withStyles(styles)(connect(
  mapStateToProps,
  mapDispatchToProps
)(EditSyncSheet)));
