import React from 'react';
import { Input, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";

import { log } from '../../utils';

const styles = _ => ({
  display: {
    cursor: 'pointer',
    lineHeight: 1.9
  },
});

class InlineEditor extends React.Component {
  constructor(props) {
    super(props);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.edit = this.edit.bind(this);
    this.save = this.save.bind(this);
    this.cancel = this.cancel.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  state = {
    editing: false,
    value: this.props.value
  }

  toggleEdit = (e) => {
    e.stopPropagation();
    if (this.state.editing) {
      this.cancel();
    } else {
      this.edit();
    }
  };

  edit = () => {
    this.setState({
      editing: true
    }, () => {

    });
  };

  save = (newValue) => {
    this.setState({
      editing: false
    }, () => {
      if (this.isValueChanged(newValue)) {
        this.setState({
          value: newValue
        }, () => {
          this.props.onSave(newValue);
        });
      }
    });
  };

  cancel = () => {
    this.setState({
      editing: false
    });
  };

  isValueChanged = (value) => {
    return this.state.value !== value
  };

  handleKeyDown = (key, value) => {
    switch (key) {
      case 'Enter':
      case 'Escape':
        this.save(value);
        break;
      default:
        break;
    }
  };


  componentDidUpdate(prevProps) {
    log('InlineEditor.componentDidMount', this.props);
    if (this.props.value !== prevProps.value) {
      this.setState({ editing: false, value: this.props.value });
    }
  }

  render() {
    log('InlineEditor.render', this.props)
    let editOnClick = true;
    const { editing, value } = this.state;
    const { classes } = this.props;

    if (this.props.editOnClick !== undefined) {
      editOnClick = this.props.editOnClick;
    }

    return (
      <>
        {
          editing
            ?
            <Input
              defaultValue={value}
              onBlur={(e) => {
                const { target } = e;
                const { value } = target;
                this.save(value);
              }}
              onKeyDown={(e) => {
                const { key, target } = e;
                const { value } = target;
                this.handleKeyDown(key, value);
              }}
              autoFocus
            />
            :
            <>
              {/* <ArrowTooltip title="Click to edit" placement="bottom"> */}
              <span className={classes.display} onClick={editOnClick ? this.toggleEdit : undefined}>
                {value}
              </span>
              {/* </ArrowTooltip> */}
              {" "}
              <Typography variant="caption" component="span" color="secondary">(click to edit)</Typography>
            </>
        }
      </>
    )
  }
}

InlineEditor.propTypes = {
  onSave: PropTypes.func.isRequired
};


export default withStyles(styles)(InlineEditor);