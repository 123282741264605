import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Script from 'react-load-script';

class GoogleScript extends Component {
  constructor(props) {
    super(props);

    this.enable = this.enable.bind(this);
    this.onScriptError = this.onScriptError.bind(this);
    this.onScriptLoaded = this.onScriptLoaded.bind(this);
    this.state = {
      disabled: true,
    };
  }

  enable() {
    this.setState({
      disabled: false
    });
  }

  handleSigninSuccess(res) {
    /*
      offer renamed response keys to names that match use
    */
    const basicProfile = res.getBasicProfile();
    const authResponse = res.getAuthResponse();
    this.props.onSuccess({
      tokenId: authResponse.id_token,
      googleUserId: basicProfile.getId(),
      picture: basicProfile.getImageUrl(),
      email: basicProfile.getEmail(),
      name: basicProfile.getName(),
      givenName: basicProfile.getGivenName(),
      familyName: basicProfile.getFamilyName(),
    });
  }

  onScriptError() {

  }

  onScriptLoaded() {
    const {
      clientId,
      cookiePolicy,
      autoLoad,
      isSignedIn,
      fetchBasicProfile,
      redirectUri,
      discoveryDocs,
      scope,
      accessType,
      signInType,
      onFailure,
    } = this.props;


    const params = {
      client_id: clientId,
      cookie_policy: cookiePolicy,
      fetch_basic_profile: fetchBasicProfile,
      discoveryDocs,
      redirect_uri: redirectUri,
      scope,
      access_type: accessType
    };


    window.gapi.load('auth2', () => {
      this.enable();
      const auth2 = window.gapi.auth2;
      if (signInType !== 'authorize') {

        auth2.init(params).then(
          res => {
            if (isSignedIn && res.isSignedIn.get()) {
              this.handleSigninSuccess(res.currentUser.get())
            } else {
              onFailure({ reason: 'SIGN_IN_REQUIRED' })
            }
          },
          err => onFailure(err)
        )

        if (autoLoad) {
          this.signIn()
        }
      }
    });
  }

  render() {

    return (<Script
      url={this.props.jsSrc}
      onError={this.onScriptError}
      onLoad={this.onScriptLoaded} />
    );
  }
}



GoogleScript.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onFailure: PropTypes.func.isRequired,
  clientId: PropTypes.string.isRequired,
  jsSrc: PropTypes.string,
  onRequest: PropTypes.func,
  scope: PropTypes.string,
  redirectUri: PropTypes.string,
  cookiePolicy: PropTypes.string,
  fetchBasicProfile: PropTypes.bool,
  prompt: PropTypes.string,
  autoLoad: PropTypes.bool,
  disabled: PropTypes.bool,
  discoveryDocs: PropTypes.array,
  responseType: PropTypes.string,
  isSignedIn: PropTypes.bool,

  accessType: PropTypes.string,
  authStateShare: PropTypes.func,
  signInType: PropTypes.string
};

GoogleScript.defaultProps = {
  scope: 'profile email openid',
  accessType: 'online',
  cookiePolicy: 'single_host_origin',
  fetchBasicProfile: true,
  isSignedIn: true,

  onRequest: () => { },
  jsSrc: 'https://apis.google.com/js/api.js',
  signInType: ''
};

export default GoogleScript;
