import React from 'react';
import { connect } from 'react-redux';
import {
  CssBaseline,
  Paper,
  Grid
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { GoogleLogin } from '../google';
import { createUserFromGAC, setSignedIn, getAccount } from '../../redux/actions';
import { log } from '../../utils';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(https://s3-us-west-2.amazonaws.com/static-images.bank2sheets.com/logo4-1.png)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '300px',
    backgroundPosition: 'center',
    backgroundColor: '#EAEFF1'
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function SignIn(props) {
  const classes = useStyles();

  const handleSigninSuccess = async (event) => {
    log("handleSigninSuccess", event);
    await props.createUserFromGAC(event);
    await props.setSignedIn(true);
    // await props.getAccount();
  };

  const handleSigninError = (event) => {
    log(event);
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={12} md={12} className={classes.image} />
      <Grid item xs={12} sm={12} md={12} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          {/* <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar> */}
          <Typography variant="h1" component="h1" color="primary">
            Bank2Sheets
          </Typography>
          <br /><br />
          <GoogleLogin
            color="primary"
            accessType="offline"
            buttonText="Connect Google Sheets"
            className=""
            clientId={process.env['REACT_APP_GOOGLE_CLIENT_ID']}
            isSignedIn={true}
            prompt='consent'
            onFailure={handleSigninError}
            offline={true}
            onSuccess={handleSigninSuccess}
            redirectUri={process.env['REACT_APP_GOOGLE_REDIRECT_URI']}
            responseType="code"
            scope="email profile openid https://www.googleapis.com/auth/spreadsheets https://www.googleapis.com/auth/drive.file"
            theme="dark">Log in with Google</GoogleLogin>


          {/* <Box mt={5}>
            <Copyright />
          </Box> */}
        </div>
      </Grid >
    </Grid >
  );
}

const mapStateToProps = (state) => (
  {
    account: state.account
  }
);

const mapDispatchToProps = {
  createUserFromGAC,
  setSignedIn,
  getAccount
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignIn);