import React from "react";
import { connect } from 'react-redux';
import { withStyles } from "@material-ui/core/styles";
import PlaidItemManagement from './PlaidItemManagement';



const styles = theme => ({
  root: {
    flexGrow: 1,
    borderRadius: '1px'
  },
  tabs: {
    borderBottom: '1px solid #aaa'
  }
});

class CenteredTabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: 0 };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event, newValue) {
    this.setState({ value: newValue });
  }

  render() {
    return (
      <>
        {this.props.account.accountId ? <PlaidItemManagement /> : ''}
      </>
    );
  }
}

const mapStateToProps = (state) => (
  {
    account: state.account
  }
)

const mapDispatchToProps = {

};


export default withStyles(styles)(connect(
  mapStateToProps,
  mapDispatchToProps
)(CenteredTabs));
