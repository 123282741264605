import React from 'react';
import { connect } from 'react-redux';
import {
  Container
} from '@material-ui/core'

import PlaidItemsContainer from '../../plaid/PlaidItemsContainer';
import {
  listPlaidItems
} from '../../../redux/actions';
import { log } from '../../../utils';



class PlaidItemManagement extends React.Component {
  componentDidMount() {
    log('PlaidItemManagement.componentDidMount', this.props);
    this.props.listPlaidItems(this.props.account.accountId);
  }

  componentDidUpdate() {
    log('PlaidItemManagement.componentDidUpdate', this.props);
  }


  render() {
    log('PlaidItemManagement.render', this.props)
    const { account, plaid } = this.props;
    const { items, renderEmptyListMessage } = plaid;
    return (
      <Container maxWidth="md">
        <PlaidItemsContainer accountId={account.accountId} items={items} renderEmptyListMessage={renderEmptyListMessage} />
      </Container>
    );
  }
}

PlaidItemManagement.propTypes = {
};

const mapStateToProps = (state) => ({
  account: state.account,
  plaid: state.plaid
});

const mapDispatchToProps = {
  listPlaidItems
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlaidItemManagement);
