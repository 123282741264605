function handleResponse(res) {
  return {
    tokenId: res.id_token,
    googleUserId: res.getId(),
    picture: res.getImageUrl(),
    email: res.getEmail(),
    name: res.getName(),
    givenName: res.getGivenName(),
    familyName: res.getFamilyName(),
  };
}


module.exports.refreshIdToken = () => {

  const params = {
    client_id: process.env['REACT_APP_GOOGLE_CLIENT_ID'],
    cookie_policy: 'single_host_origin',
    fetch_basic_profile: true,
    redirect_uri: process.env['REACT_APP_GOOGLE_REDIRECT_URI'],
    access_type: 'offline',
    scope: 'email profile openid https://www.googleapis.com/auth/spreadsheets https://www.googleapis.com/auth/drive.file'
  };

  return new Promise((resolve, reject) => {
    window.gapi.load('auth2', () => {
      window.gapi.auth2.init(params).then((res) => {
        if (res.isSignedIn.get()) {
          const currentUser = res.currentUser.get();
          let authResponse = currentUser.getAuthResponse();
          let basicProfile = {};

          if ((new Date()).getTime() > authResponse.expires_at) {
            currentUser.reloadAuthResponse();
            authResponse = currentUser.getAuthResponse();
            basicProfile = res.currentUser.get().getBasicProfile();
            basicProfile.id_token = authResponse.id_token;
            return resolve(handleResponse(basicProfile));
          }

          basicProfile = res.currentUser.get().getBasicProfile();
          basicProfile.id_token = authResponse.id_token;
          return resolve(handleResponse(basicProfile));
        } else {
          return reject({ reason: 'SIGN_IN_REQUIRED' });
        }
      });
    });
  });
}