import React from 'react';
import {
  Link
} from '@material-ui/core';

export default function ButtonLink(props) {
  const { text, url } = props;
  return (

    <Link
      target="_blank"
      rel="noopener"
      href={url}
    >
      {text}

    </Link>
  );
}