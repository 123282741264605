import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Page from './Page';
import Header from './Header';
import Footer from './Footer';



const styles = {
  root: {
    display: 'flex',
    minHeight: '100vh',
  },
  appContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  mainContent: {
    flex: 1,
    padding: '48px 36px 0',
    background: '#eaeff1',
  },
};

class Dashboard extends React.Component {
  componentDidMount() {

  }

  render() {
    const {
      classes,
      headerHomeRoute,
      headerLeftRoutes,
      headerRightRoutes,
      footerRoutes,
      pageRoutes
    } = this.props;

    return (
      <>
        <div className={classes.root}>
          <div className={classes.appContent}>
            <Header homeRoute={headerHomeRoute} leftRoutes={headerLeftRoutes} />
            <main className={classes.mainContent}>
              <Page routes={[
                ...pageRoutes,
                ...headerLeftRoutes,
                ...headerRightRoutes,
                ...footerRoutes
              ]} />
            </main>
            <Footer routes={footerRoutes} />
          </div>
        </div>
      </>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  headerLeftRoutes: PropTypes.arrayOf(PropTypes.object).isRequired,
  headerRightRoutes: PropTypes.arrayOf(PropTypes.object).isRequired,
  footerRoutes: PropTypes.arrayOf(PropTypes.object).isRequired
};




const mapStateToProps = state => (state);

export default withStyles(styles)(connect(
  mapStateToProps,
  null
)(Dashboard));
