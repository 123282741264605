import { combineReducers } from 'redux'
import {
  CREATE_ACCOUNT,
  GET_ACCOUNT,
  SIGNED_IN,
  SIGNED_OUT,
  CREATE_GOOGLE_SPREADSHEET,
  GET_GOOGLE_SPREADSHEETS,
  UPDATE_GOOGLE_SPREADSHEET_TITLE,
  DELETE_GOOGLE_SPREADSHEET,
  CREATE_PLAID_ITEM,
  GET_PLAID_ITEMS,
  EXISTING_PLAID_ITEMS_LINKED,
  PLAID_ITEM_UNLINKED,
  REPAIR_PLAID_ITEM_SUCCESS
} from '../actions/types';

const account = (state = {}, action) => {
  switch (action.type) {
    case CREATE_ACCOUNT:
    case GET_ACCOUNT:
      return { ...state, ...action.payload };
    case SIGNED_OUT:
      return { isSignedIn: false };
    case SIGNED_IN:
      return { ...state, isSignedIn: true };
    default:
      return state;
  }
};

const google = (state = { googleSpreadsheets: [], renderEmptyListMessage: false }, action) => {
  switch (action.type) {
    case SIGNED_OUT:
      return { googleSpreadsheets: [], renderEmptyListMessage: false };

    case GET_GOOGLE_SPREADSHEETS:
      return { ...state, googleSpreadsheets: action.payload, renderEmptyListMessage: !action.payload.length };
    case EXISTING_PLAID_ITEMS_LINKED:
      for (let st of state.googleSpreadsheets) {
        if (st.spreadsheetId === action.payload.spreadsheetId) {
          st.plaidItems = st.plaidItems.concat(action.payload.items);
        }
      }
      return { ...state, googleSpreadsheets: state.googleSpreadsheets };
    case CREATE_PLAID_ITEM:
      for (let st of state.googleSpreadsheets) {
        if (st.spreadsheetId === action.payload.spreadsheetId) {
          st.plaidItems.push(action.payload.item);
        }
      }
      return { ...state, googleSpreadsheets: state.googleSpreadsheets };
    case PLAID_ITEM_UNLINKED:
      let syncTarget = state.googleSpreadsheets.find(st => st.spreadsheetId === action.payload.spreadsheetId);
      let itemIndex = syncTarget.plaidItems.findIndex(i => i.itemId === action.payload.itemId);
      syncTarget.plaidItems.splice(itemIndex, 1);
      return { ...state, googleSpreadsheets: state.googleSpreadsheets };

    case DELETE_GOOGLE_SPREADSHEET:
    case CREATE_GOOGLE_SPREADSHEET:
    case UPDATE_GOOGLE_SPREADSHEET_TITLE:
    default:
      return state;
  }
};

const plaid = (state = { items: [], renderEmptyListMessage: false }, action) => {
  switch (action.type) {
    case SIGNED_OUT:
      return { items: [], renderEmptyListMessage: false };

    case GET_PLAID_ITEMS:
      return { ...state, items: action.payload, renderEmptyListMessage: !action.payload.length };
    case REPAIR_PLAID_ITEM_SUCCESS:
      state.items.forEach((item) => {
        if (item.itemId === action.payload.itemId) {
          item.error = null;
        }
      });
      return { ...state };

    case CREATE_PLAID_ITEM:
    default:
      return state;
  }
};

export default combineReducers({
  google,
  plaid,
  account
});