import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({});

class Error extends React.Component {
  render() {
    return (
      <>
        <h1>404 Error</h1>
      </>
    );
  }
}

Error.propTypes = {
};

export default withStyles(styles)(Error);
