
import axios from 'axios';
import { refreshIdToken } from '../../components/google/google-util';
import { setSignedInStatus } from '../../services/auth';
import {
  GET_ACCOUNT,
  SIGNED_IN,
  SIGNED_OUT,
  CREATE_ACCOUNT,
  GET_GOOGLE_SPREADSHEETS,
  CREATE_PLAID_ITEM,
  REPAIR_PLAID_ITEM_SUCCESS,
  GET_PLAID_ITEMS,
  EXISTING_PLAID_ITEMS_LINKED,
  PLAID_ITEM_UNLINKED
} from './types';
import { logError } from '../../utils';

axios.defaults.baseURL = process.env['REACT_APP_API_URL'];



export const setSignedIn = (isSignedIn) => {
  setSignedInStatus(isSignedIn);
  return {
    type: isSignedIn ? SIGNED_IN : SIGNED_OUT
  };
};


export const createGoogleSpreadsheet = (accountId, title) => {

  return (dispatch) => {
    refreshIdToken().then(({ tokenId }) => {
      return axios.post(`accounts/${accountId}/google/spreadsheets`,
        { title },
        {
          headers: {
            Authorization: `Bearer ${tokenId}`,
            'x-api-key': process.env['REACT_APP_API_KEY']
          }
        }).then(_ => {
          dispatch(listGoogleSpreadsheets(accountId))
        })
        .catch(error => {
          throw (error);
        });
    }).catch(error => {
      logError(error)
      throw (error);
    });
  };
};


export const listGoogleSpreadsheets = (accountId) => {
  return (dispatch) => {
    refreshIdToken().then(({ tokenId }) => {

      return axios.get(`accounts/${accountId}/google/spreadsheets`,
        {
          headers: {
            Authorization: `Bearer ${tokenId}`,
            'x-api-key': process.env['REACT_APP_API_KEY']
          }
        }).then(response => {
          dispatch(listGoogleSpreadsheetsSuccess(response.data))
        }).catch(error => {
          throw (error);
        });
    }).catch(error => {
      logError(error)
      throw (error);
    });
  };
};

export const listGoogleSpreadsheetsSuccess = (response) => {
  return {
    type: GET_GOOGLE_SPREADSHEETS,
    payload: response.googleSpreadsheets
  };
};

export const updateGoogleSpreadsheetTitle = (accountId, spreadsheetId, title) => {
  return (dispatch) => {
    refreshIdToken().then(({ tokenId }) => {
      return axios.patch(`accounts/${accountId}/google/spreadsheets/${spreadsheetId}`,
        { title },
        {
          headers: {
            Authorization: `Bearer ${tokenId}`,
            'x-api-key': process.env['REACT_APP_API_KEY']
          }
        }).then(_ => {
          dispatch(listGoogleSpreadsheets(accountId))
        }).catch(error => {
          throw (error);
        });
    }).catch(error => {
      logError(error)
      throw (error);
    });
  };
};


export const deleteGoogleSpreadsheet = (accountId, spreadsheetId) => {
  return (dispatch) => {
    refreshIdToken().then(({ tokenId }) => {
      return axios.delete(`accounts/${accountId}/google/spreadsheets/${spreadsheetId}`,
        {
          headers: {
            Authorization: `Bearer ${tokenId}`,
            'x-api-key': process.env['REACT_APP_API_KEY']
          }
        }).then(_ => {
          dispatch(listGoogleSpreadsheets(accountId))
        }).catch(error => {
          throw (error);
        });
    }).catch(error => {
      logError(error)
      throw (error);
    });
  };
};


export const createPlaidItem = (accountId, metadata) => {
  return (dispatch) => {
    refreshIdToken().then(({ tokenId }) => {
      return axios.post(`accounts/${accountId}/plaid/items`, metadata,
        {
          headers: {
            Authorization: `Bearer ${tokenId}`,
            'x-api-key': process.env['REACT_APP_API_KEY']
          }
        }).then(response => {
          if (metadata.spreadsheetId) {
            dispatch(plaidItemCreatedSuccess(metadata.spreadsheetId, response.data));
          }
          dispatch(listPlaidItems(accountId));
        }).catch(error => {
          throw (error);
        });
    }).catch(error => {
      logError(error)
      throw (error);
    });
  };
};

export const plaidItemCreatedSuccess = (spreadsheetId, response) => {
  return {
    type: CREATE_PLAID_ITEM,
    payload: { spreadsheetId, item: response.item }
  };
};


export const listPlaidItems = (accountId) => {
  return (dispatch) => {

    refreshIdToken().then(({ tokenId }) => {
      return axios.get(`accounts/${accountId}/plaid/items`,
        {
          headers: {
            Authorization: `Bearer ${tokenId}`,
            'x-api-key': process.env['REACT_APP_API_KEY']
          }
        }).then(response => {
          dispatch(getPlaidItemsSuccess(response.data))
        }).catch(error => {
          logError(error)
          throw (error);
        });
    }).catch(error => {
      logError(error)
      throw (error);
    });
  };
};

export const getPlaidItemsSuccess = (response) => {
  return {
    type: GET_PLAID_ITEMS,
    payload: response.items
  };
};


export const repairPlaidItem = (accountId, publicToken, item) => {
  return (dispatch) => {
    refreshIdToken().then(({ tokenId }) => {
      return axios.patch(`accounts/${accountId}/plaid/items/${item.itemId}`, { publicToken },
        {
          headers: {
            Authorization: `Bearer ${tokenId}`,
            'x-api-key': process.env['REACT_APP_API_KEY']
          }
        }).then(_ => {
          dispatch(repairPlaidItemSuccess(item));
        }).catch(error => {
          throw (error);
        });
    }).catch(error => {
      logError(error)
      throw (error);
    });
  };
};

export const repairPlaidItemSuccess = (item) => {
  return {
    type: REPAIR_PLAID_ITEM_SUCCESS,
    payload: item
  };
};


export const removePlaidItem = (accountId, itemId) => {
  return (dispatch) => {
    refreshIdToken().then(({ tokenId }) => {
      return axios.delete(`accounts/${accountId}/plaid/items/${itemId}`,
        {
          headers: {
            Authorization: `Bearer ${tokenId}`,
            'x-api-key': process.env['REACT_APP_API_KEY']
          }
        }).then(_ => {
          dispatch(listPlaidItems(accountId))
        }).catch(error => {
          throw (error);
        });
    }).catch(error => {
      logError(error)
      throw (error);
    });
  };
};


export const createUserFromGAC = (code) => {
  return (dispatch) => {
    return axios.post('accounts/google',
      { code }, {
      headers: {
        'x-api-key': process.env['REACT_APP_API_KEY']
      }
    })
      .then(response => {
        dispatch(createUserFromGACSuccess(response.data))
      }).catch(error => {
        setSignedInStatus(false);
        throw (error);
      });
  }
};


export const createUserFromGACSuccess = (response) => {
  setSignedInStatus(true);
  return {
    type: CREATE_ACCOUNT,
    payload: { ...response }
  };
};



export const getAccount = () => {
  return (dispatch) => {
    refreshIdToken().then(({ tokenId }) => {
      return axios.get(`accounts`,
        {
          headers: {
            Authorization: `Bearer ${tokenId}`,
            'x-api-key': process.env['REACT_APP_API_KEY']
          }
        }).then(response => {
          dispatch(getAccountSuccess(response.data))
        }).catch(error => {
          throw (error);
        });
    });
  };
};

export const getAccountSuccess = (response) => {
  return {
    type: GET_ACCOUNT,
    payload: { ...response }
  };
};

export const createPlaidLinksToGoogleSpreadsheet = ({ accountId, spreadsheetId, items }) => {
  return (dispatch) => {
    refreshIdToken().then(({ tokenId }) => {
      return axios.post(
        `account/${accountId}/google/spreadsheets/${spreadsheetId}/plaid/items`,
        {
          items
        }, {
        headers: {
          Authorization: `Bearer ${tokenId}`,
          'x-api-key': process.env['REACT_APP_API_KEY']
        }
      }).then(response => {
        dispatch(existingPlaidItemsLinkedSuccess(spreadsheetId, items));
      }).catch(error => {
        throw (error);
      });
    }).catch(error => {
      logError(error)
      throw (error);
    });
  };
};


export const existingPlaidItemsLinkedSuccess = (spreadsheetId, items) => {
  return {
    type: EXISTING_PLAID_ITEMS_LINKED,
    payload: { spreadsheetId, items }
  };
};


export const deletePlaidLinkToGoogleSpreadsheet = ({ accountId, itemId, spreadsheetId }) => {
  return (dispatch) => {
    refreshIdToken().then(({ tokenId }) => {
      return axios.delete(
        `account/${accountId}/google/spreadsheets/${spreadsheetId}/plaid/items/${itemId}`,
        {
          headers: {
            Authorization: `Bearer ${tokenId}`,
            'x-api-key': process.env['REACT_APP_API_KEY'],
          }
        }).then(_ => {
          dispatch(plaidItemUnlinkedSuccess(spreadsheetId, itemId));
        }).catch(error => {
          throw (error);
        });
    }).catch(error => {
      logError(error)
      throw (error);
    });
  };
};


export const plaidItemUnlinkedSuccess = (spreadsheetId, itemId) => {
  return {
    type: PLAID_ITEM_UNLINKED,
    payload: { spreadsheetId, itemId }
  };
};